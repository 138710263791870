import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/Navbar";
import "../Contact/Contact.styles.css";

export const StudentProgram = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Student Programs - Cevher";
    if  (searchParams.get("mail1") === "1" ){
      alert("Başvurunuz başarıyla alınmıştır.");
    }
    else if (searchParams.get("mail1") === "0" ){
      alert("Başvurunuz gönderilemedi. Lütfen tekrar deneyiniz.");
    }
  }, []);

 
 
  
  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/intern.f8e97627.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("career")}
              </div>
              <h1 className="c-caption-header">{t("studentprograms")}</h1>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
        >
          <div
            className="c-row row--no-gutter"
            style={{ marginBottom: "2.5rem" }}
          >
            <div className="col c-col--lg-12">
              <div style={{ paddingLeft: "3rem", paddingRight: "3rem" }}>
                <div className="c-caption">
                  <div className="c-caption-title">{t("studentprograms")}</div>
                  <div className="c-caption-content">
                    <p>{t("career-studentprograms-1")}</p>
                    <p>{t("career-studentprograms-2")}</p>
                    <p>{t("career-studentprograms-3")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="c-row row--no-gutter"
            style={{ marginBottom: "2.5rem" }}
          >
            <div className="col c-col--lg-6">
              <div className="c-career-pad-sides">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("career-studentprograms-internship")}
                  </div>
                  <div className="c-caption-content">
                    <p>
                      <strong>
                        {t("career-studentprograms-intern-1-bold")}
                      </strong>
                      {t("career-studentprograms-intern-1")}
                    </p>
                    <p>
                      <strong>
                        {t("career-studentprograms-intern-2-bold")}
                      </strong>
                      {t("career-studentprograms-intern-2")}
                    </p>
                    <p>{t("career-studentprograms-intern-apply")}</p>
                  </div>
                  <div style={{ marginTop: "1.5rem" }}>
                    <div
                      className="c-button"
                      onClick={() =>
                        document
                          .getElementById("apply-form-area")
                          .scrollIntoView()
                      }
                    >
                      {t("career-studentprograms-apply")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3" style={{ width: "100%" }}>
                <img
                  src={require("../../assets/content/intern-1.ac2e629d.jpg")}
                  alt="Staj Fırsatı"
                  className="c-ratio-element"
                />
              </div>
            </div>
          </div>
          <div
            className="c-row row--no-gutter u-flex-row-reverse"
            style={{ marginBottom: "2.5rem" }}
          >
            <div className="col col--lg-6">
              <div className="c-career-pad-sides">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("career-studentprograms-parttime")}
                  </div>
                  <div className="c-caption-content">
                    <p>{t("career-studentprograms-parttime-desc")}</p>
                    <p>
                      <strong>
                        {t("career-studentprograms-who-can-apply")}
                      </strong>
                    </p>
                    <p>{t("career-studentprograms-parttime-1-desc")}</p>
                    <p>
                      <strong>
                        {t("career-studentprograms-what-can-you-do")}
                      </strong>
                    </p>
                    <p>{t("career-studentprograms-parttime-2-desc")}</p>
                  </div>
                  <div style={{ marginTop: "1.5rem" }}>
                    <div
                      className="c-button"
                      onClick={() =>
                        document
                          .getElementById("apply-form-area")
                          .scrollIntoView()
                      }
                    >
                      {t("career-studentprograms-apply")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3" style={{ width: "100%" }}>
                <img
                  src={require("../../assets/content/intern-2.a83f8c9f.jpg")}
                  alt="Staj Fırsatı"
                  className="c-ratio-element"
                />
              </div>
            </div>
          </div>
          <div
            className="c-row row--no-gutter"
            style={{ marginBottom: "2.5rem" }}
          >
            <div className="col c-col--lg-6">
              <div className="c-career-pad-sides">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("career-studentprograms-undergraduate")}
                  </div>
                  <div className="c-caption-content">
                    <p>{t("career-studentprograms-undergraduate-desc")}</p>
                    <p>
                      <strong>
                        {t("career-studentprograms-who-can-apply")}
                      </strong>
                    </p>
                    <p>{t("career-studentprograms-undergraduate-1-desc")}</p>
                    <p>
                      <strong>
                        {t("career-studentprograms-what-can-you-do")}
                      </strong>
                    </p>
                    <p>{t("career-studentprograms-undergraduate-2-desc")}</p>
                  </div>
                  <div style={{ marginTop: "1.5rem" }}>
                    <div
                      className="c-button"
                      onClick={() =>
                        document
                          .getElementById("apply-form-area")
                          .scrollIntoView()
                      }
                    >
                      {t("career-studentprograms-apply")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3" style={{ width: "100%" }}>
                <img
                  src={require("../../assets/content/intern-3.809ad2ad.jpg")}
                  alt="Staj Fırsatı"
                  className="c-ratio-element"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div style={{ marginTop: "2.5rem" }} id="apply-form-area">
          <div className="c-container">
            <div
              className="c-row"
              style={{
                marginLeft: "-1rem",
                marginRight: "-1rem",
              }}
            >
              <div className="col c-col--lg-8 c-col--offset-lg-2">
                <h3>{t("career-studentprograms-student-application")}</h3>
                <p>{t("career-studentprograms-student-application-desc")}</p>
                <form
                  action="https://app.cevherdigital.com/phpmail/staj.php"
                  autoComplete="off"
                  method="POST"
                  encType="multipart/form-data"
                >
                  <input
                    type="hidden"
                    name="_subject"
                    value="Cevher Career Submission"
                  />
                  <input
                    type="hidden"
                    name="_next"
                    value="https://www.cevher.com/#/student-programs"
                  />
                  <input type="hidden" name="_template" value="table" />
                  <input type="hidden" name="_captcha" value="false" />
                  <div
                    className="c-row"
                    style={{
                      marginLeft: "-1rem",
                      marginRight: "-1rem",
                      boxSizing: "border-box",
                    }}
                  >
                    <div className="col c-col--lg-12">
                      <div
                        style={{ marginTop: "1.5rem", position: "relative" }}
                      >
                        <span className="c-career-form-group-label">
                          <label>
                            {t("career-studentprograms-form-application-type")}
                          </label>
                        </span>
                        <select
                          name="application_type"
                          className="c-career-form-control"
                          id="intern_application_type"
                        >
                          <option>
                            {t(
                              "career-studentprograms-form-application-type-1"
                            )}
                          </option>
                          <option>
                            {t(
                              "career-studentprograms-form-application-type-2"
                            )}
                          </option>
                          <option>
                            {t(
                              "career-studentprograms-form-application-type-3"
                            )}
                          </option>
                          <option>
                            {t(
                              "career-studentprograms-form-application-type-4"
                            )}
                          </option>
                          <option>
                            {t(
                              "career-studentprograms-form-application-type-5"
                            )}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="col c-col--lg-6"
                      style={{ boxSizing: "border-box" }}
                    >
                      <div style={{ marginTop: "1.5rem" }}>
                        <span className="c-career-form-group-label">
                          <label>{t("contact-name")} *</label>
                        </span>
                        <input
                          name="first_name"
                          type="text"
                          required
                          className="c-career-form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col c-col--lg-6"
                      style={{ boxSizing: "border-box" }}
                    >
                      <div style={{ marginTop: "1.5rem" }}>
                        <span className="c-career-form-group-label">
                          <label>{t("contact-surname")} *</label>
                        </span>
                        <input
                          name="last_name"
                          type="text"
                          required
                          className="c-career-form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col c-col--lg-12"
                      style={{ boxSizing: "border-box" }}
                    >
                      <div style={{ marginTop: "1.5rem" }}>
                        <span className="c-career-form-group-label">
                          <label>{t("contact-email")} *</label>
                        </span>
                        <input
                          name="email"
                          type="text"
                          required
                          className="c-career-form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col c-col--lg-12"
                      style={{ boxSizing: "border-box" }}
                    >
                      <div style={{ marginTop: "1.5rem" }}>
                        <span className="c-career-form-group-label">
                          <label>{t("contact-pdf")} *</label>
                        </span>
                        <input
                          type="file"
                          name="attachment"
                          accept="application/pdf"
                          required
                          className="c-career-form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col c-col--lg-12"
                      style={{ boxSizing: "border-box" }}
                    >
                      <div style={{ marginTop: "1.5rem" }}>
                        <span className="c-career-form-group-label">
                          <label>
                            {t("career-studentprograms-form-suitable")}
                          </label>
                        </span>
                        <input
                          name="suitable_date"
                          placeholder={t(
                            "career-studentprograms-form-suitable-placeholder"
                          )}
                          type="text"
                          required
                          className="c-career-form-control"
                        />
                      </div>
                    </div>
                    <div
                      className="col c-col--lg-12"
                      style={{ boxSizing: "border-box" }}
                    >
                      <div style={{ marginTop: "1.5rem" }}>
                        <span className="c-career-form-group-label">
                          <label>
                            {t("career-studentprograms-form-motivation")}
                          </label>
                        </span>
                        <textarea
                          name="motivation"
                          type="text"
                          required
                          className="c-career-form-control"
                          style={{ height: "auto" }}
                        />
                      </div>
                    </div>
                    <div
                      className="col c-col--lg-12"
                      style={{ boxSizing: "border-box" }}
                    >
                      <div style={{ marginTop: "1.5rem" }}>
                        <span className="c-career-form-group-label">
                          <label>
                            {t("career-studentprograms-form-previous-task")}
                          </label>
                        </span>
                        <textarea
                          name="previous_tasks"
                          type="text"
                          required
                          className="c-career-form-control"
                          style={{ height: "auto" }}
                        />
                      </div>
                    </div>
                    <div className="c-col c-col--lg-12">
                      <p>
                        {t("contact-clarification-1")}
                        <a href="/#/sustainability/protection-of-personal-data">
                          {" "}
                          {t("contact-clarification-2")}
                        </a>{" "}
                        {t("contact-clarification-3")}
                      </p>
                    </div>
                    <div
                      className="c-col c-col--lg-6"
                      style={{ boxSizing: "border-box" }}
                    >
                      <input
                        type="submit"
                        className="c-button"
                        value={t("contact-submit")}
                        style={{ marginTop: "1.5rem" }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
