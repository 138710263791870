import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./Career.styles.css";

const Career = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    document.title = "Career - Cevher";
    if  (searchParams.get("mail1") === "1" ){
      alert("Başvurunuz başarıyla alınmıştır.");
    }
    else if (searchParams.get("mail1") === "0" ){
      alert("Başvurunuz gönderilemedi. Lütfen tekrar deneyiniz.");
    }
  }, []);

  
 
    
    // searchParams.get("mail2") === "1" && alert("Başvurunuz gönderilemedi. Lütfen tekrar deneyiniz.");

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/career.d588ee6e.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("career")}
              </div>
              <h1 className="c-caption-header">{t("career-header")}</h1>
            </div>
          </div>
        </div>
        <div className="c-container" style={{ marginTop: "2.5rem" }}>
          <div className="c-row row--no-gutter">
            <div className="c-col c-career-pad-sides c-career-container">
              <div className="c-caption-title ">{t("career-title")}</div>
              <p>
                {t("career-1")}
                <br />
                <br />
                {t("career-2")}
              </p>
              <ul
                className="c-career-list"
                style={{
                  marginTop: "1.5rem",
                  marginBottom: "1.5rem",
                }}
              >
                <li>{t("career-list-1")}</li>
                <li>{t("career-list-2")}</li>
                <li>{t("career-list-3")}</li>
                <li>{t("career-list-4")}</li>
                <li>{t("career-list-5")}</li>
                <li>{t("career-list-6")}</li>
                <li>{t("career-list-7")}</li>
                <li>{t("career-list-8")}</li>
                <li>{t("career-list-9")}</li>
                <li>{t("career-list-10")}</li>
                <li>{t("career-list-11")}</li>
                <li>{t("career-list-12")}</li>
                <li>{t("career-list-13")}</li>
              </ul>
              <p>
                {t("career-3")}
                <br />
                <br />
                {t("career-4")}
                <br />
                <br />
                {t("career-5")}
              </p>
              <div style={{ maxWidth: "50%", marginTop: "1.5rem" }}>
                <a
                  href="https://www.kariyer.net/firma-profil/cevher-jant-sanayi-a-s-164621-89470"
                  className="c-career-button"
                >
                  {t("career-openpositions")}
                </a>
              </div>
            </div>
          </div>
          <div
            className="c-row row--no-gutter "
            style={{ marginBottom: "2.5rem" }}
          >
            <div className="col c-col--lg-6">
              <div className="c-career-text-wrapper c-career-pad-sides">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("career-business-ethics-title")}
                  </div>
                  <div class="c-caption-content">
                    <p>
                      <strong>{t("career-business-ethics-desc")}</strong>
                    </p>
                    <p>{t("career-business-ethics-1")}</p>
                    <p>{t("career-business-ethics-2")}</p>
                    <p>{t("career-business-ethics-3")}</p>
                    <p>{t("career-business-ethics-4")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/uploads/career-2.63ad293d.jpg")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
          <div className="c-row row--no-gutter c-row-reverse u-flex-row-reverse">
            <div className="col c-col--lg-6">
              <div className="c-career-text-wrapper">
                <div className="c-caption">
                  <div className="c-caption-title">
                    {t("career-cevher-academy")}
                  </div>
                  <div class="c-caption-content">
                    <p>
                      <strong>{t("career-cevher-academy-subtitle")}</strong>
                    </p>
                    <p>{t("career-cevher-academy-desc")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col c-col--lg-6">
              <div className="c-ratio c-ratio--4-3">
                <img
                  src={require("../../assets/uploads/career-3.7dac3d82.png")}
                  alt="Product-1"
                  className="c-products-img"
                />
              </div>
            </div>
          </div>
          <hr />
          <div style={{ marginTop: "2.5rem" }}>
            <div className="c-container">
              <div className="c-row">
                <div className="c-col c-col--lg-8 c-col--offset-lg-2">
                  <h3 style={{ fontSize: "1.5rem" }}>
                    {t("career-join-cevher")}
                  </h3>
                  <p>{t("career-join-cevher-desc")}</p>
                  <form
                    action="https://app.cevherdigital.com/phpmail/index.php"
                    autoComplete="off"
                    method="POST"
                    encType="multipart/form-data"
                  >
                    <input
                      type="hidden"
                      name="_subject"
                      value="Cevher Career Submission"
                    />
                    <input
                      type="hidden"
                      name="_next"
                      value="https://www.cevher.com/#/career"
                    />
                    <input type="hidden" name="_captcha" value="false" />
                    <input type="hidden" name="_template" value="table" />
                    <div className="c-career-row">
                      <div
                        className="c-col c-col--lg-6"
                        style={{ boxSizing: "border-box" }}
                      >
                        <div className="c-career-form-group">
                          <span className="c-career-form-group-label">
                            <label>{t("contact-name")} *</label>
                          </span>
                          <input
                            type="text"
                            name="first_name"
                            required
                            className="c-career-form-group-input c-career-form-control"
                          />
                        </div>
                      </div>
                      <div
                        className="c-col c-col--lg-6"
                        style={{ boxSizing: "border-box" }}
                      >
                        <div className="c-career-form-group">
                          <span className="c-career-form-group-label">
                            <label>{t("contact-surname")}*</label>
                          </span>
                          <input
                            type="text"
                            name="last_name"
                            id="contact_lastName"
                            required
                            className="c-career-form-group-input c-career-form-control"
                          />
                        </div>
                      </div>
                      <div
                        className="c-col c-col--lg-6 "
                        style={{ boxSizing: "border-box" }}
                      >
                        <div className="c-career-form-group">
                          <span className="c-career-form-group-label">
                            <label>{t("contact-email")} *</label>
                          </span>
                          <input
                            type="email"
                            name="email"
                            required
                            className="c-career-form-group-input c-career-form-control"
                          />
                        </div>
                      </div>
                      <div
                        className="c-col c-col--lg-6"
                        style={{ boxSizing: "border-box" }}
                      >
                        <div className="c-career-form-group">
                          <span className="c-career-form-group-label">
                            <label>{t("contact-phonenumber")} *</label>
                          </span>
                          <input
                            type="text"
                            name="phone"
                            required
                            className="c-career-form-group-input c-career-form-control"
                          />
                        </div>
                      </div>
                      <div
                        className="c-col c-col--lg-12"
                        style={{ boxSizing: "border-box" }}
                      >
                        <div className="c-career-form-group">
                          <span className="c-career-form-group-label">
                            <label>{t("contact-pdf")} *</label>
                          </span>
                          <input
                            type="file"
                            name="attachment"
                            accept="application/pdf"
                            required
                            className="c-career-form-group-input c-career-form-control"
                          />
                        </div>
                      </div>
                      <div
                        className="c-col c-col--lg-12 message"
                        style={{ boxSizing: "border-box" }}
                      >
                        <div className="c-career-form-group">
                          <span className="c-career-form-group-label">
                            <label>{t("contact-message")} *</label>
                          </span>
                          <textarea
                            type="text"
                            name="message"
                            required
                            className="form-group-input message-input"
                          />
                        </div>
                      </div>
                      <div className="c-col c-col--lg-12">
                        <p>
                          {t("contact-clarification-1")}
                          <a href="/#/sustainability/protection-of-personal-data">
                            {" "}
                            {t("contact-clarification-2")}
                          </a>{" "}
                          {t("contact-clarification-3")}
                        </p>
                      </div>
                      <div
                        className="c-col c-col--lg-6"
                        style={{ boxSizing: "border-box" }}
                      >
                        <input
                          type="submit"
                          className="c-button"
                          value={t("contact-submit")}
                          style={{ marginTop: "1.5rem" }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
